import { useState, useEffect, useRef } from 'react';
import { constants } from '../../../../helpers';
import { ToggleInput, TextField } from '../../../../components/forms';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import { FaCircle } from 'react-icons/fa';
import { CiCircleRemove } from 'react-icons/ci';
import { CiCircleCheck } from 'react-icons/ci';
import RestoredDevicesTable from './RestoredDevicesTable';
import { publishFirmwareDeviceSetting } from '../shared/PublishDeviceSettings';

const LocalStorageBackup = ({
  canChangeSettings,
  deviceInfos,
  localStoragePermissions,
  publishPayload,
  resourceList,
  publishFirmwareDeviceChannalSetting,
  updateStore,
  showRecordEvent,
  resourceListRecordEvent,
  switchToVideoTab,
}) => {
  const { t } = useTranslation();
  const [storageEnableModal, setStorageEnableModal] = useState(false);
  const [restorationModal, setRestorationModal] = useState(false);
  const [formatCardModal, setFormatCardModal] = useState(false);
  const [connectionState, setConnectionState] = useState(
    deviceInfos?.connectionStatus
  );
  const [recondOnlyEvents, setRecondOnlyEvents] = useState(
    deviceInfos?.properties?.recEventBased === 'true' ? true : false
  );
  const [localStorageDeviceEnable, setLocalStorageDeviceEnable] = useState(
    deviceInfos?.properties?.storageBackup === 'true' ? true : false
  );
  const [sdCardStatus, setSdCardStatus] = useState(
    deviceInfos?.properties?.sdCardStatus
  );
  const [freeSizeMB, setFreeSizeMB] = useState(
    deviceInfos?.properties?.freeSizeMB
  );
  const [totalSizeMB, setTotalSizeMB] = useState(
    deviceInfos?.properties?.totalSizeMB
  );
  const [sdcardDetectStatus, setSdcardDetectStatus] = useState(
    deviceInfos?.properties?.sdcardDetectStatus === ('true' || true)
      ? true
      : false
  );

  useEffect(() => {
    setRecondOnlyEvents(
      deviceInfos?.properties?.recEventBased === 'true' ? true : false
    );
    setConnectionState(deviceInfos?.connectionStatus);
    setLocalStorageDeviceEnable(deviceInfos?.properties?.storageBackup);
    setSdCardStatus(deviceInfos?.properties?.sdCardStatus);
    setFreeSizeMB(deviceInfos?.properties?.freeSizeMB);
    setTotalSizeMB(deviceInfos?.properties?.totalSizeMB);
    setSdcardDetectStatus(deviceInfos?.properties?.sdcardDetectStatus);
  }, [deviceInfos]);

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  return (
    <>
      <Formik
        initialValues={{
          recondOnlyEvents:
            deviceInfos?.properties?.recEventBased === 'true' ? true : false,
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="text-start device-network-settings-block">
              {showRecordEvent && (
                <div className="cloud-storage-recording-profile mt-4">
                  <div className="cloud-storage-text-holder">
                    <div className="section-heading">
                      {constants.CHANNEL_CLOUD_STORAGE_RECORDING_PROFILE}
                    </div>
                    <div
                      className="cloude-storage-text"
                      onClick={() => {
                        switchToVideoTab();
                      }}
                    >
                      {
                        constants.CHANNEL_CLOUD_STORAGE_RECORDING_PROFILE_SETTINGS
                      }
                    </div>
                  </div>
                  <div className="device-info-section m-t-16">
                    <div>
                      <div className="space-btn-text codec-selection">
                        <div className="inside-title">
                          {constants.CHANNEL_CLOUD_STORAGE_RECORDING247}
                        </div>
                        <Field
                          type="radio"
                          name="recondOnlyEvents247"
                          // value={false}
                          checked={!recondOnlyEvents}
                          onClick={() => {
                            setRecondOnlyEvents(false);

                            const status = getCheckPropertyStatus(
                              false,
                              'recEventBased'
                            );
                            const property = {
                              recEventBased: 'false',
                            };
                            updateStore(property);
                            publishFirmwareDeviceChannalSetting(
                              status,
                              resourceListRecordEvent[0],
                              publishPayload
                            );
                          }}
                          disabled={!canChangeSettings}
                        />
                      </div>
                      {deviceInfos?.properties?.recEventBased !== undefined && (
                        <div className="space-btn-text codec-selection">
                          <div className="inside-title">
                            {constants.CHANNEL_CLOUD_STORAGE_RECORD_ONLY_EVENT}
                          </div>
                          <Field
                            type="radio"
                            name="recondOnlyEvents"
                            // value={true}
                            checked={recondOnlyEvents === true}
                            onClick={() => {
                              setRecondOnlyEvents(true);
                              const status = getCheckPropertyStatus(
                                true,
                                'recEventBased'
                              );
                              const property = {
                                recEventBased: 'true',
                              };
                              updateStore(property);
                              publishFirmwareDeviceChannalSetting(
                                status,
                                resourceListRecordEvent[0],
                                publishPayload
                              );
                            }}
                            disabled={!canChangeSettings}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="section-heading mb-2 mt-4">
                <label>{constants.CHANNELS_INFO_LOCAL_STORAGE}</label>
              </div>
              <div className="local-storage-message">
                {constants.CHANNELS_INFO_LOCAL_STORAGE_MESSAGE}
              </div>
              <div className="device-info-section">
                <div
                  className={`firmware-container-storage fm-container device-firmware-container`}
                >
                  <div className="firmware-update storage-enable-button">
                    <Col>
                      <ToggleInput
                        label={t('CHANNELS_INFO_LOCAL_STORAGE_TITLE')}
                        classes={'p-0 m-t-0'}
                        name="fw-updt-dev-type"
                        value={localStorageDeviceEnable}
                        labelColor={true}
                        changeHandler={(e) => {
                          if (!localStorageDeviceEnable) {
                            setStorageEnableModal(true);
                          } else {
                            setLocalStorageDeviceEnable(false);
                            const status = {
                              storageBackup: false,
                              avoidReload: true,
                            };
                            updateStore(status);
                            if (showRecordEvent) {
                              publishFirmwareDeviceChannalSetting(
                                status,
                                resourceList[6],
                                publishPayload
                              );
                            } else {
                              publishFirmwareDeviceSetting(
                                status,
                                resourceList[6],
                                publishPayload
                              );
                            }
                          }
                        }}
                        disabledClass={
                          (sdCardStatus &&
                            sdCardStatus ===
                              constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING) ||
                          deviceInfos?.connectionStatus !==
                            constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                          !canChangeSettings
                            ? 'cursor-auto'
                            : ''
                        }
                        disabled={
                          (sdCardStatus &&
                            sdCardStatus ===
                              constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING) ||
                          deviceInfos?.connectionStatus !==
                            constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                          !canChangeSettings
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div>
              {localStorageDeviceEnable && (
                <div className="device-info-section mt-3">
                  <div className="storage-header-container">
                    <div className="storage-header">
                      {constants.CHANNELS_INFO_LOCAL_STORAGE_SLOT_TITLE}
                    </div>
                    <div className="storage-subheader">
                      {sdcardDetectStatus ? (
                        <CiCircleCheck
                          size={25}
                          style={{ marginRight: 2 }}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--success_48')}
                        />
                      ) : (
                        <CiCircleRemove
                          size={25}
                          style={{ marginRight: 2 }}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_64')}
                        />
                      )}
                      {sdcardDetectStatus
                        ? constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_INSERTED
                        : constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_NOT_INSERTED}
                    </div>
                  </div>
                  <div className="storage-info-container">
                    <div className="w-100">
                      <div className="storage-text">
                        {constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS}
                      </div>
                      <div className="storage-header-container p-0">
                        <div className="storage-subheader">
                          <>
                            {!sdcardDetectStatus ? (
                              <>
                                <FaCircle
                                  size={10}
                                  className="status-icon-ready-to-claim margin-right-4"
                                />
                                {
                                  constants.CHANNELS_INFO_LOCAL_STORAGE_NO_STATUS
                                }
                              </>
                            ) : null}
                            {sdcardDetectStatus ? (
                              <>
                                {!sdCardStatus ? (
                                  <FaCircle
                                    size={10}
                                    className="status-icon-ready-to-claim margin-right-4"
                                  />
                                ) : sdCardStatus &&
                                  sdCardStatus ===
                                    constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_ERROR ? (
                                  <FaCircle
                                    size={10}
                                    className="status-icon-offline"
                                  />
                                ) : sdCardStatus &&
                                  (sdCardStatus ===
                                    constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING ||
                                    sdCardStatus ===
                                      constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_RECORDING ||
                                    sdCardStatus ===
                                      constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_RECORDING_SOON) ? (
                                  <FaCircle
                                    size={10}
                                    className="status-icon-online"
                                  />
                                ) : (
                                  <FaCircle
                                    size={10}
                                    className="status-icon-entered"
                                  />
                                )}
                                {sdCardStatus
                                  ? sdCardStatus
                                  : constants.CHANNELS_INFO_LOCAL_STORAGE_NO_STATUS}
                              </>
                            ) : null}
                          </>
                        </div>
                        {Boolean(localStoragePermissions['format-drive']) ? (
                          <div
                            className={
                              sdcardDetectStatus &&
                              sdCardStatus !==
                                constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING &&
                              deviceInfos?.connectionStatus ===
                                constants.DEVICES_ONLINE_CONNECTION_STATUS
                                ? 'format-card-eabled'
                                : 'format-card-disabled'
                            }
                            onClick={() => {
                              if (
                                !sdcardDetectStatus ||
                                sdCardStatus ===
                                  constants.CHANNELS_INFO_LOCAL_STORAGE_STATUS_FORMATTING ||
                                !canChangeSettings
                              ) {
                                return;
                              }
                              setFormatCardModal(true);
                            }}
                          >
                            {constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_TITLE}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="storage-text">Available Storage</div>
                      <div className="d-flex">
                        <span>
                          {sdcardDetectStatus
                            ? freeSizeMB >= 1024
                              ? (Number(freeSizeMB) / 1024).toFixed(2)
                              : freeSizeMB
                            : '0.0'}
                          {sdcardDetectStatus
                            ? freeSizeMB >= 1024
                              ? ' GB '
                              : ' MB '
                            : ' GB '}
                        </span>
                        <span className="storage-subheader">
                          {` / `}
                          {sdcardDetectStatus
                            ? totalSizeMB >= 1024
                              ? (Number(totalSizeMB) / 1024).toFixed(2)
                              : totalSizeMB
                            : '0.0'}
                          {sdcardDetectStatus
                            ? totalSizeMB >= 1024
                              ? ' GB'
                              : ' MB'
                            : ' GB'}
                        </span>
                      </div>
                    </div>
                    <PrimaryButton
                      className="btn btn-primary-outline"
                      type="button"
                      width="100%"
                      height="56px"
                      fontSize="1.125rem"
                      lineHeight="24px"
                      backgroundColor="transparent"
                      hoverBackgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      borderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      hoverBorderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      borderWidth="1px"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      hoverColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      onClick={() => setRestorationModal(true)}
                    >
                      {constants.CHANNELS_INFO_LOCAL_STORAGE_RESTORATION}
                    </PrimaryButton>
                  </div>
                </div>
              )}

              {/* Enable local storage - modal  */}
              <SiteModal
                modalTitle={
                  'Are you sure you want to enable SD card recording for this device?'
                }
                showModal={storageEnableModal}
                hideModal={() => {
                  setStorageEnableModal(false);
                }}
                classes="localstorage-setup"
              >
                <TextBlock
                  className="mb-3"
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_ENABLE_TITLE}
                </TextBlock>
                <PrimaryButton
                  className="btn btn-primary-outline mb-3"
                  type="button"
                  width="100%"
                  borderWidth="1.5px"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  onClick={(e) => {
                    setStorageEnableModal(false);
                    setLocalStorageDeviceEnable(true);
                    const status = {
                      storageBackup: true,
                      avoidReload: true,
                    };
                    updateStore(status);
                    if (showRecordEvent) {
                      publishFirmwareDeviceChannalSetting(
                        status,
                        resourceList[6],
                        publishPayload
                      );
                    } else {
                      {
                        publishFirmwareDeviceSetting(
                          status,
                          resourceList[6],
                          publishPayload
                        );
                      }
                    }
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_ENABLE_CONFIRM}
                </PrimaryButton>
                <PrimaryButton
                  className="btn btn-primary"
                  type="button"
                  width="100%"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor="transparent"
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_96')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  onClick={() => {
                    setStorageEnableModal(false);
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_ENABLE_CANCEL}
                </PrimaryButton>
              </SiteModal>

              {/* Restoration - modal  */}
              <SiteModal
                showModal={restorationModal}
                hideModal={() => {
                  setRestorationModal(false);
                }}
                classes="restortion-maodal"
                size="xl"
                modalTitle={constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY}
              >
                <RestoredDevicesTable
                  showRecordEvent={showRecordEvent}
                  deviceInfos={deviceInfos}
                />
              </SiteModal>

              {/* Format SD card local storage - modal  */}
              <SiteModal
                modalTitle={
                  constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_MESSAGE
                }
                showModal={formatCardModal}
                hideModal={() => {
                  setFormatCardModal(false);
                }}
                classes="localstorage-setup"
              >
                <TextBlock
                  className="mb-3"
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_SUBMESSAGE}
                </TextBlock>
                <PrimaryButton
                  className="btn btn-primary"
                  type="button"
                  width="100%"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor="transparent"
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_96')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  onClick={() => {
                    setFormatCardModal(false);
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_CANCEL}
                </PrimaryButton>
                <PrimaryButton
                  className="btn btn-primary-outline mt-3"
                  type="button"
                  width="100%"
                  borderWidth="1.5px"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  onClick={(e) => {
                    setFormatCardModal(false);
                    if (
                      connectionState !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS
                    ) {
                      return;
                    }
                    const status = {};
                    if (showRecordEvent) {
                      publishFirmwareDeviceChannalSetting(
                        status,
                        resourceList[7],
                        publishPayload
                      );
                    } else {
                      publishFirmwareDeviceSetting(
                        status,
                        resourceList[7],
                        publishPayload
                      );
                    }
                  }}
                >
                  {constants.CHANNELS_INFO_LOCAL_STORAGE_FORMAT_CONFIRM}
                </PrimaryButton>
              </SiteModal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LocalStorageBackup;
