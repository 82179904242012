import StepIndicator from '../StepIndicator';
import { AppDefaults, constants, customEventName } from '../../../../helpers';
import '../stepsnotification.scss';
import { useState, useRef, useEffect, useCallback } from 'react';
import { PrimaryButton } from '../../../../components/common';
import NotificationDevices from '../NotificationDevices';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import { useOrganizations } from '../../../../store/OrganizationsStore';
import { useCustomerOrgLocations } from '../../../../store/CustomerOrgLocationsStore';
import { useLoggedInUserData } from '../../../../store/LoggedInAccountStore';
import { getCustomerOrgData } from '../../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import StepCustomNotification from './StepCustomNotification';

const Three = (props) => {
  const scollToRef = useRef();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { getSelectedLocation } = useCustomerOrgLocations();
  const globalLocation = getSelectedLocation(loggedInUserData?.accountId);
  const [deviceIds, setDeviceIds] = useState([]);
  const [virtualLineUuid, setVirtualLineUuid] = useState([]);
  const [deviceList, setDeviceList] = useState(
    globalLocation == undefined ||
      globalLocation?.locationId === AppDefaults.ALL_LOCATIONS_ID
      ? []
      : props.currentLocationDevices
  );
  const [selectedLocation, setSelectedLocation] = useState(
    globalLocation == undefined ||
      globalLocation?.locationId === AppDefaults.ALL_LOCATIONS_ID
      ? {}
      : globalLocation
  );
  const setStepData = useNotificationStore((state) => state.setStepsData);
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // let custOrg = getCustomerOrgData();
  // const custOrgData = custOrg[0];
  const [custOrgData, setCustOrgData] = useState();

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    if (props?.stepData?.locationId) {
      const obj = { locationId: props?.stepData?.locationId };
      setSelectedLocation(obj);
    }
    if (getStepsData()?.selectedEvents?.length > 0) {
      const selectedList = getStepsData()?.selectedEvents;
      if (customEventName.includes(selectedList[0].type)) {
        props?.setEventType('customEvent');
      }
    }
    setTimeout(() => {
      scollToRef?.current?.scrollIntoView();
    }, 100);
  }, []);

  const updateStepData = (deviceStepData) => {
    if (deviceStepData?.length > 0) {
      getDeviceSelected(deviceStepData);
    } else {
      setDeviceIds([]);
    }
  };
  const getDeviceSelected = (locationAreaDeviceList) => {
    const devices = [];
    locationAreaDeviceList?.map((itemLocation, index) => {
      const areaList = itemLocation?.areas;
      areaList?.length > 0 &&
        areaList?.map((itemArea, indexArea) => {
          const deviceList = itemArea?.areaDevice;
          deviceList?.length > 0 &&
            deviceList?.map((itemDevice, indexDevice) => {
              if (itemDevice?.deviceChecked === 1) {
                devices.push(itemDevice?.deviceId);
              }
            });
        });
    });
    setDeviceIds(devices);
  };

  const moveToNextStep = () => {
    const nextStepData = {};
    if (props?.isEdit) {
      nextStepData['triggers'] = getStepsData()?.triggers;
      nextStepData['selectedEvents'] = getStepsData()?.selectedEvents;
      nextStepData['editItem'] = getStepsData()?.editItem;
      nextStepData['devices'] = deviceIds;
      nextStepData['conditions'] = getStepsData()?.conditions;
      nextStepData['actions'] = getStepsData()?.actions;
      nextStepData['subscribers'] = getStepsData()?.subscribers;
    } else {
      nextStepData['triggers'] = props?.stepData?.triggers;
      nextStepData['selectedEvents'] = props?.stepData?.selectedEvents;
      nextStepData['devices'] = deviceIds;
    }
    if (virtualLineUuid.length) {
      const updatedTriggerList = getStepsData()?.triggers.map((item) => ({
        ...item,
        properties: {
          ...item.properties,
          ids: virtualLineUuid,
        },
      }));
      nextStepData['triggers'] = updatedTriggerList;
    }
    if (props?.eventType === 'customEvent') {
      nextStepData['locationId'] = props?.customLocationId;
    } else {
      nextStepData['locationId'] = selectedLocation?.locationId;
    }
    setStepData(nextStepData);
    props.nextStep(nextStepData);
  };

  const onHandleChange = (location) => {
    if (location != undefined) {
      setSelectedLocation(location);
    }
  };

  useEffect(() => {
    if (!props?.customLocationId?.toString()?.trim('')?.length) {
      props?.setVirtualLinesData([]);
      props?.setVirtualAreasData([]);
    } else {
      if (
        props?.notificationData?.triggers &&
        props?.notificationData?.triggers[0]?.type ===
        getStepsData()?.triggers[0]?.type
      ) {
        const Ids = props?.notificationData?.triggers[0]?.properties?.ids;
        Ids?.length && setVirtualLineUuid([...Ids]);
      } else {
        setVirtualLineUuid([]);
      }
    }
  }, [props?.customLocationId]);

  useEffect(() => {
    if (
      !props?.isEdit &&
      (globalLocation == undefined ||
        globalLocation?.locationId === AppDefaults.ALL_LOCATIONS_ID)
    ) {
      setDeviceList([]);
    } else {
      setDeviceList(props?.currentLocationDevices);
      let selectLoc;
      props?.currentLocationDevices?.map((deviceItem, index) => {
        const deviIdExist = getStepsData()?.devices?.find(
          (item) => item === deviceItem?.deviceId
        );
        if (deviIdExist) {
          selectLoc = deviceItem;
        }
      });
      if (selectLoc != undefined && selectLoc != null) {
        setSelectedLocation(selectLoc);
      }
    }
  }, [props.currentLocationDevices]);

  useEffect(() => {
    if (Object.keys(selectedLocation).length !== 0) {
      const deviceList = props.currentLocationDevices?.filter(
        (item) => item?.locationId === selectedLocation?.locationId
      );
      setDeviceList(deviceList);
    }
  }, [selectedLocation]);

  const isCustomNotificationDisabled =
    !virtualLineUuid.length || !props?.customLocations?.length;
  const isVirtualDataSelected = () =>
    (isShowArea ? props?.virtualAreasData : props?.virtualLinesData)?.some(
      (item) =>
        virtualLineUuid.includes(
          isShowArea ? (props?.customEventType === 'md-include-area' ? item['md-include-area-uuid'] : item['virtual-area-uuid']) : item['virtual-line-uuid']
        )
    );
  const isShowArea =
    customEventName.includes(getStepsData()?.triggers[0]?.type) &&
    getStepsData()?.triggers[0]?.type !== 'ai.duclo.virtualLineDetected';
  return (
    <div ref={scollToRef} className="create-notification">
      <StepIndicator {...props} />
      <div className="devices-step-three">
        <div className="step-title">
          {props?.eventType === 'customEvent'
            ? isShowArea
              ? props?.customEventType === 'md-include-area'
                ? constants.LOCATION_MOTION_AREAS
                : constants.LOCATION_VIRTUAL_AREAS
              : constants.LOCATION_LINE_CROSS
            : constants.DEVICE_AND_LOCATION}
        </div>
        <div className="device-selection-row">
          <div className="step-title-desc">
            {props?.eventType === 'customEvent'
              ? isShowArea
                ? props?.customEventType === 'md-include-area'
                  ? constants.NOTIFICATION_SEARCH_HEADER_MOTION_AREA
                  : constants.LOCATION_DESCRIPTION_VIRTUAL_AREA
                : constants.LOCATION_DESCRIPTION_LINE_CROSS
              : constants.NOTIFICATION_CREATE_NOTIFICATION_SELECT_DEVICES}
          </div>
        </div>
        {props?.eventType === 'customEvent' ? (
          <StepCustomNotification
            customLocations={props?.customLocations}
            virtualLinesData={props?.virtualLinesData}
            virtualAreasData={props?.virtualAreasData}
            setDeviceIds={setDeviceIds}
            deviceIds={deviceIds}
            virtualLineUuid={virtualLineUuid}
            setVirtualLineUuid={setVirtualLineUuid}
            setCustomLocationId={props?.setCustomLocationId}
            customLocationId={props?.customLocationId}
            devicesDetails={props?.devicesDetails}
            isShowArea={isShowArea}
            handleSelectCustomLocation={props?.handleSelectCustomLocation}
            customEventType={props?.customEventType}
          />
        ) : (
          <>
            <div className="device-selection-row mt-3">
              <div className="step-title-desc-section">
                {constants.SELECT_LOCATION_TITLE}
              </div>
            </div>
            <div className="location-main">
              {custOrgData?.locations?.map((item, index) => {
                return (
                  <div className="location-list">
                    <div className="location-name"> {item?.locationName}</div>
                    <input
                      checked={
                        selectedLocation?.locationId === item?.locationId
                      }
                      className="input-radio-events filed-radio"
                      type="radio"
                      name="radio"
                      onChange={(e) => onHandleChange(item)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="device-selection-row mt-3">
              <div className="step-title-desc-section">
                {constants.SELECT_DEVICE}
              </div>
              <div className="count-layout">
                <div className="count-text">{deviceIds?.length}</div>
                <div className="selected-text">
                  {constants.NOTIFICATION_CREATE_NOTIFICATION_SELECTED}
                </div>
              </div>
            </div>

            <NotificationDevices
              isCameraStatus={
                getStepsData()?.selectedEvents?.filter(
                  (item) => item?.type === 'ai.duclo.CameraStatus',
                )?.length > 0
              }
              deviceList={deviceList}
              deviceIds={deviceIds}
              setDeviceData={(deviceData) => {
                updateStepData(deviceData);
              }}
              {...props}
            />
          </>
        )}
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={
            props?.eventType === 'customEvent'
              ? isCustomNotificationDisabled || !isVirtualDataSelected()
              : deviceIds?.length === 0
          }
          onClick={() => moveToNextStep()}
        >
          {constants.DEVICES_CONTINUE_TEXT}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Three;
