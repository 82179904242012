import { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/Info-circle.svg';

import {
  DateSelector,
  ListBlock,
  PrimaryButton,
  SiteToast,
} from '../../../../components/common';
import {
  constants,
  LicenseStatuses,
  OfferingTypeEnum,
  ServiceTypeEnum,
  Utils,
  AppDefaults,
} from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';
import timezones from '../../../../data/support/timezone.json';

import './AddLicenseForm.scss';
import { getSelectedOrganization } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import { findTimeZoneFromSelectedValue } from '../../../../helpers/commonUtils';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';

const AddLicenseForm = ({
  deviceId,
  deviceTimeZone,
  orgId,
  expiryDate,
  callBack,
  deviceLicenses,
}) => {
  const myDivRef = useRef(null);
  const orgInfo = useSelector(getOrgInfo);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [groupedLicenses, setGroupedLicenses] = useState({});
  const [targetDeviceId, setTargetDeviceId] = useState(deviceId);
  const [licenseKey, setLicenseKey] = useState(null);
  const [selectedLicenseInfo, setSelectedLicenseInfo] = useState({});
  const [activationDate, setActivationDate] = useState(
    moment.utc(expiryDate).valueOf()
  );
  const [minDate, setMinDate] = useState();
  const sortedDeviceLicenses = [...deviceLicenses].sort(
    (a, b) => a.expiryDate - b.expiryDate
  );
  const [showLoader, setShowLoader] = useState(false);

  const { licenses, getLicenses, setLicenses } = useLicensesStore();
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const timeZones = timezones?.data;

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    setMinDate(getDataValue());
  }, []);

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    const filterByProductName = (productName) => {
      if (availableLicenses.length < 1) {
        return [];
      }

      return availableLicenses.filter(
        (license) => license.productDisplayName === productName
      );
    };

    const generateAvailableLicenses = async () => {
      let organizationLicenses;
      let productNamesSet = new Set();
      let licensesByName = {};
      let unassignedLicenses, productSet;

      if (!Array.isArray(licenses) || licenses.length < 1) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
      }

      // Retrieve all VMS System-based licenses only
      organizationLicenses = getLicenses(
        false,
        false,
        ServiceTypeEnum?.VMS_SERVICE,
        false
      );

      // Filter for unassigned licenses (available)
      unassignedLicenses = organizationLicenses.filter(
        (license) =>
          license.licenseStatus === LicenseStatuses.UNASSIGNED ||
          license.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED
      );

      unassignedLicenses.forEach((license) =>
        productNamesSet.add(license.productDisplayName)
      );

      productSet = [...productNamesSet];

      productSet.forEach((productName) => {
        licensesByName[productName] = unassignedLicenses.filter(
          (license) => license.productDisplayName === productName
        );
      });

      await new Promise((resolve) => {
        setGroupedLicenses(licensesByName);
        resolve();
      });
    };

    generateAvailableLicenses();
  }, []);

  const calculateAdjustedExpiryDate = (deviceLicense) => {
    if (
      deviceLicense?.offeringType === OfferingTypeEnum.ONBOARDING &&
      selectedLicenseInfo.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED
    ) {
      return moment.utc(selectedLicenseInfo?.activeDate);
    } else if (
      deviceLicense?.offeringType === OfferingTypeEnum.ONBOARDING &&
      deviceLicense.licenseStatus === LicenseStatuses.ACTIVE
    ) {
      return moment();
    } else if (
      deviceLicense?.offeringType === OfferingTypeEnum.EVAL &&
      selectedLicenseInfo &&
      selectedLicenseInfo?.offeringType
    ) {
      if (selectedLicenseInfo?.offeringType === OfferingTypeEnum.EVAL) {
        const adjustedDate = moment(
          deviceLicense?.expiryDate -
            deviceLicense?.gracePeriod * AppDefaults.DAY_IN_MILLISECONDS
        );
        return adjustedDate;
      } else if (selectedLicenseInfo?.offeringType === OfferingTypeEnum.PAID) {
        return moment();
      }
    } else {
      if (
        selectedLicenseInfo &&
        selectedLicenseInfo.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED
      ) {
        return moment.utc(selectedLicenseInfo?.activeDate);
      } else {
        const adjustedDate = moment(
          deviceLicense?.expiryDate -
            deviceLicense?.gracePeriod * AppDefaults.DAY_IN_MILLISECONDS
        );
        return adjustedDate;
      }
    }
  };

  const hasActivePaidLicense = (deviceId) => {
    if (!deviceId) return false;

    return licenses.some(
      (license) =>
        license.deviceId === deviceId &&
        license?.offeringType !== OfferingTypeEnum.ONBOARDING &&
        !Utils.isLicenseExpired(license)
    );
  };

  const licenseIsOnGracePeriod = (activeAttachedLicense) => {
    const currentTimestamp = Date.now();
    const expDate = activeAttachedLicense?.expiryDate;
    const gracePeriod = activeAttachedLicense?.gracePeriod;

    let gracePeriodEpoch = gracePeriod * AppDefaults.DAY_IN_MILLISECONDS;
    const actualExpiryDate = expDate - gracePeriodEpoch;

    if (expDate > currentTimestamp && actualExpiryDate < currentTimestamp) {
      return true;
    } else {
      return false;
    }
  };

  const getActiveDeviceLicense = () => {
    if (sortedDeviceLicenses?.length > 0) {
      const activeLicenseDataObject = sortedDeviceLicenses?.filter(
        (license) => license.licenseStatus === LicenseStatuses.ACTIVE
      );
      if (activeLicenseDataObject?.length > 0) {
        return activeLicenseDataObject[0];
      } else {
        return sortedDeviceLicenses[0];
      }
    }
  };

  const shouldActiveUnassignedBeDisabled = (deviceId, license) => {
    const licenseStatus = license?.licenseStatus;
    if (!deviceId || !licenseStatus) return false;
    const activeAttachedLicense = getActiveDeviceLicense();

    let attachedLicenseOnGracePeriod = false;

    if (
      activeAttachedLicense?.licenseStatus?.toUpperCase() ===
        LicenseStatuses.EXPIRED &&
      licenseIsOnGracePeriod(activeAttachedLicense) &&
      activeAttachedLicense?.offeringType !== OfferingTypeEnum.ONBOARDING
    ) {
      attachedLicenseOnGracePeriod = true;
    } else {
      attachedLicenseOnGracePeriod = false;
    }

    if (
      activeAttachedLicense?.offeringType === OfferingTypeEnum.PAID &&
      !attachedLicenseOnGracePeriod
    ) {
      if (
        licenseStatus?.toUpperCase() === LicenseStatuses.ACTIVE_UNASSIGNED ||
        license?.offeringType === OfferingTypeEnum.EVAL
      ) {
        return true;
      } else if (licenseStatus?.toUpperCase() === LicenseStatuses.UNASSIGNED) {
        return false;
      }
    } else if (
      activeAttachedLicense?.offeringType === OfferingTypeEnum.PAID &&
      attachedLicenseOnGracePeriod
    ) {
      if (
        (licenseStatus?.toUpperCase() === LicenseStatuses.UNASSIGNED ||
          licenseStatus?.toUpperCase() === LicenseStatuses.ACTIVE_UNASSIGNED) &&
        license?.offeringType !== OfferingTypeEnum.EVAL
      ) {
        return false;
      } else {
        return true;
      }
    } else if (
      activeAttachedLicense?.offeringType === OfferingTypeEnum.ONBOARDING &&
      licenseStatus?.toUpperCase() === LicenseStatuses.ACTIVE_UNASSIGNED
    ) {
      return false;
    } else if (
      activeAttachedLicense?.offeringType === OfferingTypeEnum.EVAL &&
      licenseStatus?.toUpperCase() === LicenseStatuses.ACTIVE_UNASSIGNED
    ) {
      return true;
    } else if (
      licenseStatus?.toUpperCase() === LicenseStatuses.ACTIVE_UNASSIGNED
    ) {
      if (activeAttachedLicense?.length === 0 || !activeAttachedLicense) {
        return false;
      } else {
        return hasActivePaidLicense(deviceId);
      }
    } else {
      return false;
    }
  };

  const verifyToDisableDate = () => {
    const deviceLicense = getActiveDeviceLicense();
    if (Object.values(selectedLicenseInfo)?.length === 0) {
      return true;
    } else if (deviceLicense?.offeringType === OfferingTypeEnum.ONBOARDING) {
      return (
        selectedLicenseInfo?.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED
      );
    } else if (
      deviceLicense?.offeringType === OfferingTypeEnum.EVAL &&
      selectedLicenseInfo &&
      selectedLicenseInfo?.offeringType === OfferingTypeEnum.PAID
    ) {
      // existing license is EVAL and
      // selected license is PAID then enable date picker
      return false;
    } else if (
      (selectedLicenseInfo &&
        selectedLicenseInfo?.licenseStatus ===
          LicenseStatuses.ACTIVE_UNASSIGNED) ||
      Object.values(sortedDeviceLicenses)?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderAccordions = () => {
    let licenseProductNames = Object.keys(groupedLicenses);
    const accordions = [];

    licenseProductNames.forEach((licenseProductName, idx) => {
      accordions.push(
        <Accordion className="product-accordion" key={`accordion${idx}`}>
          <AccordionSummary
            className="product-summary"
            expandIcon={<ExpandMoreIcon size={16} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div>
              <Typography className="product-name">
                {licenseProductName}
              </Typography>
              <div className="num-of-licenses">
                {Utils.replaceStringValues(
                  constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_NUMBER_OF_LICENSES_AVAILABLE,
                  '$numOfLicenses',
                  groupedLicenses[licenseProductName].length
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="product-details">
            <ul className="product-list">
              {groupedLicenses[licenseProductName].map(
                (license, licenseIndex) => (
                  <li key={`license${licenseIndex}`}>
                    <div className="product-list-item-wrapper">
                      <div className="product-list-item">
                        <div className="product-list-item-name">
                          {license?.licenseKey}
                        </div>
                        <div>
                          <RadioGroup
                            aria-labelledby="controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={'value'}
                            onChange={() => {}}
                          >
                            <Radio
                              className="product-list-item-radio-button"
                              checked={licenseKey === license?.licenseKey}
                              onChange={(e) => {
                                setLicenseKey(license?.licenseKey);
                                setSelectedLicenseInfo(license);
                                const activeAttachedLicense =
                                  getActiveDeviceLicense();
                                if (
                                  Object.values(sortedDeviceLicenses)
                                    ?.length === 0
                                ) {
                                  if (
                                    license?.licenseStatus?.toUpperCase() ===
                                    LicenseStatuses.ACTIVE_UNASSIGNED
                                  ) {
                                    setActivationDate(license?.activeDate);
                                    setMinDate(moment.utc(license?.activeDate));
                                  } else if (
                                    license?.licenseStatus?.toUpperCase() ===
                                    LicenseStatuses.UNASSIGNED
                                  ) {
                                    const currentEpochTimestamp = Date.now();
                                    setActivationDate(currentEpochTimestamp);
                                    setMinDate(
                                      moment.utc(currentEpochTimestamp)
                                    );
                                  }
                                } else if (
                                  Object.values(sortedDeviceLicenses)?.length >
                                  0
                                ) {
                                  if (
                                    license?.licenseStatus?.toUpperCase() ===
                                    LicenseStatuses.ACTIVE_UNASSIGNED
                                  ) {
                                    if (
                                      activeAttachedLicense?.offeringType ===
                                      OfferingTypeEnum.ONBOARDING
                                    ) {
                                      setActivationDate(license?.activeDate);
                                      setMinDate(
                                        moment.utc(license?.activeDate)
                                      );
                                    }
                                  } else if (
                                    license?.licenseStatus?.toUpperCase() ===
                                    LicenseStatuses.UNASSIGNED
                                  ) {
                                    if (
                                      activeAttachedLicense?.offeringType ===
                                      OfferingTypeEnum.ONBOARDING
                                    ) {
                                      const currentEpochTimestamp = Date.now();
                                      setActivationDate(currentEpochTimestamp);
                                      setMinDate(
                                        moment.utc(currentEpochTimestamp)
                                      );
                                    } else if (
                                      activeAttachedLicense?.offeringType ===
                                      OfferingTypeEnum.EVAL
                                    ) {
                                      if (
                                        license?.offeringType ===
                                        OfferingTypeEnum.EVAL
                                      ) {
                                        let maxExpiryDate =
                                          sortedDeviceLicenses[
                                            sortedDeviceLicenses.length - 1
                                          ]?.expiryDate;
                                        let gracePeriod =
                                          sortedDeviceLicenses[
                                            sortedDeviceLicenses.length - 1
                                          ]?.gracePeriod *
                                          24 *
                                          60 *
                                          60 *
                                          1000;
                                        const adjustedDate =
                                          maxExpiryDate - gracePeriod;
                                        setActivationDate(adjustedDate);
                                        setMinDate(moment.utc(adjustedDate));
                                      } else if (
                                        license?.offeringType ===
                                        OfferingTypeEnum.PAID
                                      ) {
                                        const currentEpochTimestamp =
                                          Date.now();
                                        setActivationDate(
                                          currentEpochTimestamp
                                        );
                                        setMinDate(
                                          moment.utc(currentEpochTimestamp)
                                        );
                                      }
                                    } else {
                                      let maxExpiryDate =
                                        sortedDeviceLicenses[
                                          sortedDeviceLicenses.length - 1
                                        ]?.expiryDate;
                                      let gracePeriod =
                                        sortedDeviceLicenses[
                                          sortedDeviceLicenses.length - 1
                                        ]?.gracePeriod *
                                        24 *
                                        60 *
                                        60 *
                                        1000;
                                      const adjustedDate =
                                        maxExpiryDate - gracePeriod;
                                      Utils.vmsLogger().log(
                                        'adjustedDate: ',
                                        adjustedDate
                                      );
                                      setActivationDate(adjustedDate);
                                      setMinDate(moment.utc(adjustedDate));
                                    }
                                  }
                                }
                              }}
                              value={license?.licenseKey}
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'A' }}
                              disabled={shouldActiveUnassignedBeDisabled(
                                deviceId,
                                license
                              )}
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
      );
    });

    return accordions;
  };

  const getTimezoneLocationByValue = (timezoneValue) => {
    if (!timezoneValue) return;

    let val = findTimeZoneFromSelectedValue(timezoneValue) || '';

    return val.location;
  };

  const addLicenseToDevice = async () => {
    setShowToast(false);
    setUserMsg('');
    try {
      const reqBody = {
        licenses: [
          {
            deviceId: targetDeviceId,
            licenseKey: licenseKey,
            activeDate: activationDate,
          },
        ],
      };
      setShowLoader(true);
      const orgIdAssign = orgId ? orgId : orgInfo?.orgId;
      const res = await axios.put(
        `/user/orgs/${orgIdAssign}/licenses/assign/v2`,
        reqBody,
        Utils.requestHeader()
      );

      if (res?.data?.meta?.code === 200) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
        setShowLoader(false);
        callBack();
      } else {
        scrollToDiv();
        setShowToast(true);
        setUserMsg(res?.data?.meta.userMsg);
      }
    } catch (error) {
      scrollToDiv();
      setShowToast(true);
      setUserMsg(error.msg);
    } finally {
      setShowLoader(false);
    }
  };

  const isLicenseAvailable = () => {
    return Object.keys(groupedLicenses).length > 0;
  };

  const scrollToDiv = () => {
    if (myDivRef?.current) {
      myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getDataValue = () => {
    if (Object.values(sortedDeviceLicenses).length > 0) {
      const activeDeviceLicense = getActiveDeviceLicense();
      return calculateAdjustedExpiryDate(activeDeviceLicense);
    } else {
      if (
        selectedLicenseInfo &&
        selectedLicenseInfo.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED
      ) {
        return moment.utc(selectedLicenseInfo.activeDate);
      } else {
        return moment(activationDate);
      }
    }
  };

  const setActualTimeInActivationDate = (epochValue) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();
    const currentMilliseconds = currentTime.getMilliseconds();
    const newDate = new Date(epochValue);
    newDate.setHours(currentHours);
    newDate.setMinutes(currentMinutes);
    newDate.setSeconds(currentSeconds);
    newDate.setMilliseconds(currentMilliseconds);
    setActivationDate(newDate.getTime());
  };

  return (
    <>
      <Container ref={myDivRef}>
        <Row>
          <Col>
            {!isLicenseAvailable() ? (
              <div className="mb-4 warning-container">
                <div className="warning-message">
                  <InfoCircleIcon />
                  {constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE}
                </div>
              </div>
            ) : (
              <>
                {
                  constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_SELECT_LICENSE_TITLE
                }
              </>
            )}
          </Col>
        </Row>
        {isLicenseAvailable() && (
          <Row>
            <Col>
              <ListBlock
                className="list-block license-block"
                contentWrapperClass="text-start"
              >
                {renderAccordions()}
              </ListBlock>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div>
              {
                constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_LICENSE_ACTIVATION_TITLE
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* TODO: put the style in a class name */}
            <div className="date-selector-wrapper">
              <DateSelector
                value={getDataValue()}
                minDate={minDate ? minDate : moment.utc(new Date().getTime())}
                disableDate={verifyToDisableDate()}
                handleChange={(epochValue) => {
                  if (epochValue) {
                    setActualTimeInActivationDate(epochValue);
                  }
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* TODO: put the style in a class name */}
            <div
              className="add-license-button-wrapper"
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            >
              <PrimaryButton
                className="confirm-btn"
                onClick={() => addLicenseToDevice()}
                loader={showLoader}
                disabled={
                  !targetDeviceId || !licenseKey || !activationDate
                    ? true
                    : false
                }
              >
                {constants.ORG_DASHBOARD_LICENSES_ADD_LICENSE_CONFIRM_LABEL}
              </PrimaryButton>
            </div>
          </Col>
        </Row>
        <SiteToast
          customCss="licenses-list-toast"
          position="top-end"
          show={showToast}
          title="Error!"
          body={userMsg}
          delay={5000}
        />
      </Container>
    </>
  );
};

export default AddLicenseForm;
